import Nav from "./Nav";
import Footer from "./Footer";
import "./styles/getApp.scss";
import Back from "./images/appBackground.webp";
import App from "./images/App.png";
import Google from "./images/googlePlay.webp";
import Apple from "./images/appStore.webp";
import AppShow from "./images/AppPage.png";
import AppShow2 from "./images/application_2.png";
import AppShow3 from "./images/application_3.png";
import {useState, useEffect} from 'react';

export default function GetApp() {
    const [mobile, setMobile] = useState(false);

    document.title = "Speed Queen | Get the APP";

    useEffect(() => {
        if (window.innerWidth < 690) {
          setMobile(true);
        } 
      }, [window.innerWidth]);
    

  return (
    <main className="app__main">
      <Nav page="app" />
      <section className="app__top">
        <img src={Back} alt="background image" className="app_background" />
        <div className="app_top_content">
          <div className="app_content_column">
            <h2>
              Get the <span>APP</span>
            </h2>
            <p>
              Enter a location, view machine availability, pay from your phone
              and you are ready to go. We’ll even let you know when your laundry
              is almost finished.
            </p>
            <strong>Your best laundry day starts now.</strong>
            <div className="column__row">
              <a
                href="https://apps.apple.com/us/app/speed-queen/id1235121730"
                target="_blank"
              >
                <img src={Apple} alt="Apple Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.alliancelaundry.app.speedqueen&hl=en&gl=US&pli=1"
                target="_blank"
              >
                <img src={Google} alt="Google Play" />
              </a>
            </div>
          </div>
          <img src={App} alt="Speed Queen app" id="mobile__app"/>
        </div>
      </section>
      <section className="app__how">
        <h2>
          How the APP <span>works</span>
        </h2>
        <div className="app_how_content_top">
          {mobile ? (
            <>
              <img src={App} alt="app" />

              <div className="content_side">
                <p>
                  <span>1</span> Download the Speed Queen app from the Apple App
                  Store or Google play store.
                </p>
                <p>
                  <span>2</span> Enter the verification code from your email
                  into the app to complete set up.
                </p>
              </div>
              <div className="content_side">
                <p>
                  <span>3</span> Select Sign Up or Log In & follow the steps to
                  set up your Speed Queen Laundry account.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="content_side">
                <p>
                  <span>1</span> Download the Speed Queen app from the Apple App
                  Store or Google play store.
                </p>
                <p>
                  <span>2</span> Enter the verification code from your email
                  into the app to complete set up.
                </p>
              </div>
              <img src={App} alt="app" />
              <div className="content_side">
                <p>
                  <span>3</span> Select Sign Up or Log In & follow the steps to
                  set up your Speed Queen Laundry account.
                </p>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="app__show">
        <div className="app_show_row">
          <img src={AppShow} alt="app screenshot" />
          <div className="app_show_text">
            <h3>How to check machine availability</h3>
            <p>
              Select your Speed Queen laundry store by entering the 6-digit
              location pin or by scanning the QR code in store. The pin can be
              found on the store locator.
            </p>
          </div>
        </div>
        <div className="app_show_row">
          <img src={AppShow2} alt="app screenshot" />
          <div className="app_show_text">
            <h3>Adding credit</h3>
            <p>
              To add money to your Speed Queen account, select “Your account
              balance” in the app and choose a payment method: credit or debit
              card, Apple Pay®, or add funds in-store with credit or debit.
            </p>
          </div>
        </div>
        <div className="app_show_row">
          <img src={AppShow3} alt="app screenshot" />
          <div className="app_show_text">
            <h3>Notifications</h3>
            <p>
              Choose to allow notifications via text, email or push to get a
              reminder of cycle completion.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}
