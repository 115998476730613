import { useEffect } from "react"

export default function Review(props) {
    useEffect(()=>{
        window.location.href = 'https://g.page/r/CRAYQETV7G4OEB0/review'
    },[])

    return (
        <></>
    )
}