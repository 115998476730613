import Nav from "./Nav";
import "./styles/home.scss";
import HomeImg from "./images/SQ_home.webp";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Pricing from "./images/pricing_bgd.webp";
import PetsIcon from "@mui/icons-material/Pets";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ContactlessIcon from "@mui/icons-material/Contactless";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Google from "./images/googlePlay.webp";
import Apple from "./images/appStore.webp";
import App from "./images/App.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Eco from "./images/eco.webp";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import WashingMachine from "./images/washingMachine.png";
import TumbleDryer from "./images/tumble_dryer_icon.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function Home() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [severity, setSeverity] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [response, setResponse] = useState("");
  const [test, setTest] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    if (document.location.hash) {
      let prices = document.getElementById("prices");
      prices.scrollIntoView();
    }
  }, []);

  const submit = () => {
    document.getElementById("submit_button").style.display = "none";
    let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (email.match(regex)) {
      if (email === "" || message === "") {
        setSeverity("warning");
        setResponse("Email and message are required");
        setWarningAlert(true);
        setTimeout(() => {
          setSeverity("");
          setResponse("");
          setWarningAlert(false);
          document.getElementById(
            "submit_button"
          ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
        }, 3500);
      } else {
        let data = {
          name: name,
          lastName: lastName,
          email: email,
          message: message,
        };
        axios
          .post("https://nitrous.rt-itservices.co.uk/speedqueen/contact", data)
          .then((res) => {
            setSeverity("success");
            setResponse("Form sent, thanks for getting in touch");
            setWarningAlert(true);
            setTimeout(() => {
              setSeverity("");
              setResponse("");
              setWarningAlert(false);
              document.getElementById(
                "submit_button"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
              setName("");
              setLastName("");
              setMessage("");
              setEmail("");
            }, 3500);
          })
          .catch((err) => {
            setSeverity("error");
            setResponse("Something went wrong, please try again");
            setWarningAlert(true);
            setTimeout(() => {
              setSeverity("");
              setResponse("");
              setWarningAlert(false);
              document.getElementById(
                "submit_button"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
            }, 3500);
          });
      }
    } else {
      setSeverity("warning");
      setResponse("Email has the wrong format, please check your email");
      setWarningAlert(true);
      setTimeout(() => {
        setSeverity("");
        setResponse("");
        setWarningAlert(false);
        document.getElementById(
          "submit_button"
        ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
      }, 3500);
    }
  };

  const handleDot = (id) => {
    console.log(id)
    let cards = document.getElementsByClassName("pricing_card")
    for (let i = 0; i < cards.length; i++){
      if (i === id){
        cards[i].style = `transform: tramslateX(0); opacity: 1; animation: none; `
      } else{
        cards[i].style = "animation: none;"
      }
    }
    setTimeout(() => {
      for (let i = 0; i < cards.length; i++){
        if (i === 0){
          cards[i].style = `animation: display 15s infinite;`
        } else if (i === 1){
          cards[i].style = `animation: display 15s infinite;`
          cards[i].style = "animation-delay: 5s;"
        }  else if (i === 2){
          cards[i].style = `animation: display 15s infinite;`
          cards[i].style = "animation-delay: 10s;"
        }
      }
      },5000)
  }

  return (
    <main className="home">
      <Nav page="home" />
      <section className="home__row">
        <div className="home_row_column">
          <h1>Meet Speed Queen, World's No.1 in Self-Service Laundry.</h1>
          <h5>Wash &amp; dry all your laundry within 1 hour.</h5>
          <button className="find__btn" onClick={() => navigate("/locations")}>
            <SearchIcon className="find_btn_icon" />
            Find your nearest launderette
          </button>
          <a href="https://www.facebook.com/speedqueenuk/" target="_blank">
            <StarIcon className="row_text_icon" />
            Drop us a review
          </a>
        </div>
        <img src={HomeImg} alt="home image" />
      </section>
      <section className="home__banner">
        <h3>Open 24/7</h3>
      </section>
      <section className="home__why">
        <h2>
          Why <span>Speed Queen?</span>
        </h2>
        <div className="home_why_row">
          <div className="why_reason">
            <img src={Eco} alt="eco icon" />
            <strong>Eco-Friendly</strong>
            <p>
              With ozone technology Speed Queen uses{" "}
              <span>40% less energy</span>. This ensures efficient water use
              with each wash and reduces carbon footprint.
            </p>
          </div>
          <div className="why_reason">
            <LocationOnIcon className="why_icon" />
            <strong>Locations</strong>
            <p>With new locations coming, use our <span>locator map</span> to find your closest store.</p>
          </div>
          <div className="why_reason">
            <StarBorderIcon className="why_icon" />
            <strong>Better machines for better results</strong>
            <p>
              Each machine neutralises over 90% of the bacterial load and have a
              <span>total sanitisation</span> system at the end of each wash
              cycle.
            </p>
          </div>
          <div className="why_reason">
            <PhoneIphoneIcon className="why_icon" />
            <strong>Join the community</strong>
            <p>
              Download our app to gain access to{" "}
              <span>exclusive rewards &amp; benefits</span> and keep up with
              latest Speed Queen news.
            </p>
          </div>
          <div className="why_reason">
            <ThumbUpOffAltIcon className="why_icon" />
            <strong>Easy to use</strong>
            <p>
              Various washing & drying programmes available depending on your
              needs. We have <span>indoor seated facilities</span> for your
              comfort while you wait.{" "}
            </p>
          </div>
          <div className="why_reason">
            <AccessTimeIcon className="why_icon" />
            <strong>Save with Speed Queen</strong>
            <p>
              <span>Save time &amp; money</span> on your weekly laundry. Get all
              your laundry done in one visit at no more costs than doing it
              elsewhere.
            </p>
          </div>
        </div>
      </section>
      <section className="home__pricing" id="prices" name="prices">
        <img src={Pricing} alt="background image" />
        <div className="home_pricing_content">
          <h2>
            Convenience at <span>affordable pricing</span>
          </h2>
          <div className="pricing_row">
            <div className="pricing_card" id={1}>
              <img
                src={WashingMachine}
                className="img_icon"
                alt="washing machine"
              />
              <h2>Wash</h2>
              <div className="card__text">
                <strong>
                  8kg wash <span>- £5.50</span>
                </strong>
                <strong>
                  14kg wash <span>- £9.00</span>
                </strong>
                <strong>
                  18kg wash <span>- £11.00</span>
                </strong>
              </div>
              <div className="line_big"></div>
              <div className="line_small"></div>
              <em>
                All our machines are <strong>100%</strong> sanitised after each
                wash.
              </em>
            </div>
            <div className="pricing_card" style={{paddingTop:"0.6%"}} id={2}>
              <img
                src={TumbleDryer}
                className="img_icon"
                alt="washing machine"
              />
              <h2>Dry</h2>
              <div className="card__text">
                <strong>
                  Every 15 mins <span>- £3.50</span>
                </strong>
              </div>
              <div className="line_big"></div>
              <div className="line_small"></div>
              <em>
                For <strong>best results</strong>, do not overload the machine,
                plus you can open the machine at any time to check if the
                laundry is dry.
              </em>
            </div>
            <div className="pricing_card" style={{paddingTop:"0.8%"}} id={3}>
              <PetsIcon className="card_icon" />
              <h2>pets</h2>
              <div className="card__text">
                <strong>
                  14kg Pet wash <span>- £9.00</span>
                </strong>
                <strong>
                  Pet dry every 15 mins <span>- £3.50</span>
                </strong>
              </div>
              <div className="line_big"></div>
              <div className="line_small"></div>
              <em>
                Specialised machines dedicated to{" "}
                <strong>heavy duty cleaning</strong> of your larger laundry
                items.
              </em>
            </div>
            <div className="pricing_content_dots">
              <FiberManualRecordIcon className="content_dot" onClick={() => handleDot(1)}/>
              <FiberManualRecordIcon className="content_dot" onClick={() => handleDot(2)}/>
              <FiberManualRecordIcon className="content_dot" onClick={() => handleDot(3)}/>
            </div>
          </div>
        </div>
      </section>
      <section className="home__how">
        <h2>
          How it <span>works</span>
        </h2>
        <div className="home_how_cards">
          <div className="home_how_card">
            <AutoModeIcon className="how_card_icon" />
            <h3>Load</h3>
            <p>Load the machine & close the door.</p>
            <p>Detergent, softener & sanitiser will be added automatically.</p>
          </div>
          <div className="home_how_card">
            <TouchAppIcon className="how_card_icon" />
            <h3>select</h3>
            <p>
              <strong>Wash:</strong> Select the desired washing program.
            </p>
            <p>
              <strong>Dryer:</strong> Select the drying temperature.
            </p>
          </div>
          <div className="home_how_card">
            <ContactlessIcon className="how_card_icon" />
            <h3>pay</h3>
            <p>
              Pay at the kiosk using phone wallet, credit/debit card or though
              the Speed Queen app.
            </p>
          </div>
          <div className="home_how_card">
            <PlayCircleOutlineIcon className="how_card_icon" />
            <h3>start</h3>
            <p>
              After making the payment press the start button at the washing
              machine.
            </p>
          </div>
        </div>
        <button onClick={() => navigate("/how-it-works")}>Find out more</button>
      </section>
      <section className="home__app">
        <div className="home_app_text">
          <h2>
            Get the <span>APP</span>
          </h2>
          <h6>
            Make your experience even better with the Speed Queen app, allowing
            you to avail of offers and discounts plus much more.
          </h6>
          <strong>Find out more</strong>
          <div className="text_row">
            <a
              href="https://apps.apple.com/us/app/speed-queen/id1235121730"
              target="_blank"
            >
              <img src={Apple} alt="Apple Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.alliancelaundry.app.speedqueen&hl=en&gl=US&pli=1"
              target="_blank"
            >
              <img src={Google} alt="Google Play" />
            </a>
          </div>
        </div>
        <img src={App} alt="app image" id="get_app_image"/>
      </section>
      <section className="home_laundry">
        <h2>
          All your <span>laundry</span> needs covered
        </h2>
        <div className="home_laundry_text">
          <h3>
            for <span>businesses</span>
          </h3>
          <p>
            Restaurants, Cafe's, Barbers, Hair salons, Care homes, B&B - sheets,
            towels, table clothes, napkins and so much more can all be washed &
            dry in 1 hour, saving you time & money so you can focus on what you
            do best.
          </p>
          <h3>
            for <span>pets</span>
          </h3>
          <p>
            Dedicated Pet Zone for all your 4-legged friend's clothing, saving
            your washing machine from getting filled with dirt and animal hair.
          </p>
          <h3>
            for <span>convenience</span>
          </h3>
          <p>
            A convenient laundry solution for you located beside your local
            shopping centres with ample parking, so you can get grocery shop &
            laundry ticked off your to-do list in one go.
          </p>
          <h3>
            for <span>you</span>
          </h3>
          <p>
            A laundry service designed for you that’s quick, clean and fresh.
            The duvet, bulky & delicate garments, outdoor work clothes, all can
            be washed & dry in our high-tech machines that use ozonated water to
            guarantee high levels of hygiene.​
          </p>
        </div>
      </section>
      <section className="home__contact">
        <div className="home_contact_text">
          <h2>
            We're here for <span>you</span>
          </h2>
          <p>We appreciate your feedback!</p>
        </div>
        <div className="home_contact_form">
          <div className="form">
            <h3>Contact Us</h3>
            <div className="contact_name">
              <div className="form_column">
                <label>First Name</label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form_column">
                <label>Last Name</label>
                <input
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>
            <div className="form__field">
              <label>
                Email <span>*</span>
              </label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="form__field">
              <label>
                Write a message <span>*</span>
              </label>
              <textarea
                cols={20}
                rows={10}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </div>
            <button id="submit_button" onClick={() => submit()}>
              Submit
            </button>
            {warningAlert ? (
              <Alert severity={severity}>{response}</Alert>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}
