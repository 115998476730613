import './styles/navigation.scss';
import Logo from './images/footer_logo.webp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from 'react-router-dom';
import XLogo from './images/TWITTER_BLK.png'
import TikTokLogo from './images/tikTok.png'

export default function Footer() {
    const navigate = useNavigate()

    return(
        <footer>
            <section className="footer__details">
                <img src={Logo} alt="Speed Queen Logo"/>
                <div className="footer_links">
                    <p onClick={() => navigate("/")}>Homepage</p>
                    <p onClick={() => navigate("/how-it-works")}>How it works</p>
                </div>
                <div className="footer_links">
                    <p onClick={() => navigate("/contact")}>Contact</p>
                    <p onClick={() => navigate("/faq")}>FAQ</p>
                </div>
                <div className="footer_links">
                    <p onClick={() => navigate("/locations")}>Launderette Locator</p>
                    <p onClick={() => navigate("/get-the-app")}>Get the APP</p>
                </div>
                <div className="footer_links">
                    <h6>Follow us on socials</h6>
                    <div className="footer_socials">
                        <a href="https://www.facebook.com/speedqueenuk/" target="_blank"><FacebookIcon className="footer_icon"/></a>
                        <a href="https://www.instagram.com/speedqueenuk/" target="_blank"><InstagramIcon className="footer_icon"/></a>
                        <a href="https://twitter.com/speedqueenuk" target="_blank"><img src={XLogo} style={{width:"40px"}} /></a>
                        <a href="https://www.tiktok.com/@speedqueen.uk" target="_blank"><img src={TikTokLogo} style={{width:"30px"}} /></a>
                    </div>
                </div>
            </section>
            <p>2023 | PANDAWASH LTD T/A SPEED QUEEN | Alliance Laundry Systems LLC- All rights reserved</p>
            <p>Developed by <a href="https://nitrosolutions.co.uk/" target="_blank">Nitro Solutions</a></p>
        </footer>
    )

}