import { MarkerF } from "@react-google-maps/api";
import MapMarkerImg from "./images/mapMarker2.png";

export default function MapMarker(props) {
    return (
        <div key={props.key}>
            <MarkerF
                icon={MapMarkerImg}
                position={{
                    lat: props.data.address.latitude,
                    lng: props.data.address.longitude,
                }}
                onClick={() => {
                    props.update(props.data.locationId);
                }}
            />
        </div>
    );
}
