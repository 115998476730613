import "./styles/faq.scss";
import { useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Services from "./tabs/Services";
import Laund from "./tabs/Laund";
import SqApp from "./tabs/SqApp";

const themeColor = createTheme({
    palette: {
      secondary: {
        main: "#e63b11",
      },
    },
  });
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


export default function Faq() {
  const [value, setValue] = useState(0);

  document.title = "Speed Queen | FAQ"

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <main className="faq__main">
      <Nav page="faq" />
      <h2>Got a question?</h2>
      <p className="faq_text_main">
        We’ve gathered your most frequently asked questions about Speed Queen
        Laundry and answered them below. Can’t find what you’re looking for?
        Drop us a message here.
      </p>
      <ThemeProvider theme={themeColor}>
        <Box sx={{ width: "95vw", margin: "auto" }} theme={themeColor}>
          <Box sx={{ p: 1 }} >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="FAQ tabs"
              textColor="secondary"
              indicatorColor="none"
              scrollButtons="auto"
              centered
            >
              <Tab label="SERVICES" {...a11yProps(0)} id="tabs_font"/>
              <Tab label="LAUNDERETTES" {...a11yProps(1)} id="tabs_font"/>
              <Tab label="SPEED QUEEN APP" {...a11yProps(2)} id="tabs_font"/>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Services />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Laund />
          </TabPanel>
          <TabPanel value={value} index={2} >
            <SqApp />
          </TabPanel>
        </Box>
      </ThemeProvider>
      <Footer />
    </main>
  );
}
