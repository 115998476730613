import "./styles/how.scss";
import Nav from "./Nav";
import Footer from "./Footer";
import CircleIcon from "@mui/icons-material/Circle";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ContactlessIcon from "@mui/icons-material/Contactless";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Loading from "./images/loading_washing.webp";
import Select from "./images/select.webp";
import Pay from "./images/pay.webp";
import Start from "./images/start.webp";
import Ozone from "./images/icon_eco.webp";
import Eco from "./images/eco.webp";
import Tutorial from "./images/tutorial.mp4";
import {useEffect} from 'react';

export default function How() {
  document.title = "Speed Queen | How it works"
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <main className="how__main">
      <Nav page="how" />
      <section className="how_top_section">
        <h2>
          How to use <span>Speed Queen</span> laundromat
        </h2>
        <h6>Using Speed Queen washing and dry machines is easy.</h6>
        <div className="how_top_section_row">
          <h3>Cleaner</h3>
          <CircleIcon className="how_icon" />
          <h3>faster</h3>
          <CircleIcon className="how_icon" />
          <h3>cheaper</h3>
        </div>
      </section>
      <section className="how_instructions">
        <div className="how_instructions_row">
          <div className="how_row_text">
            <div className="text_row">
              <AutoModeIcon className="text_icon" />
              <h2>1. Load your machine</h2>
            </div>
            <p>Load the machine &amp; close the door.</p>
            <p>Detergent, softener & sanitiser will be automatically added.</p>
          </div>
          <img src={Loading} alt="washing machine loading" />
        </div>
        <div className="how_instructions_row">
          <div className="how_row_text">
            <div className="text_row">
              <TouchAppIcon className="text_icon" />
              <h2>2. Select your desired wash or dry</h2>
            </div>
            <p>Select the Wash or Dry via the touch screen.</p>
            <p>
              Speed Queen washing machines offer different wash programmes &
              options for all types of laundry.
            </p>
          </div>
          <img src={Select} alt="washing machine loading" />
        </div>
        <div className="how_instructions_row">
          <div className="how_row_text">
            <div className="text_row">
              <ContactlessIcon className="text_icon" />
              <h2>3. Pay</h2>
            </div>
            <p>Pay at the kiosk. Using any of the 3 payment methods.</p>
            <ul>
              <li>Phone wallet (Google or Apple Pay)</li>
              <li>Credit or debit card</li>
              <li>
                Use our app. To add money to your Speed Queen account in the
                app, select “Your account balance” in the app and choose a
                payment method to add funds in-store with credit or debit.
              </li>
            </ul>
          </div>
          <img src={Pay} alt="washing machine loading" />
        </div>
        <div className="how_instructions_row">
          <div className="how_row_text">
            <div className="text_row">
              <PlayCircleOutlineIcon className="text_icon" />
              <h2>4. Start</h2>
            </div>
            <p>
              After making the payment press the start button at the washing
              machine.
            </p>
            <p>
              The door remains locked throughout the wash cycle. The dryer door
              can be opened at any time.
            </p>
          </div>
          <img src={Start} alt="washing machine loading" />
        </div>
      </section>
      <section className="how__tips">
        <h2>
          A convenient, easy to use laundry solution that helps protect the
          environment.
        </h2>
        <strong>
          At Speed Queen we use Ozone Technology to guarantee high levels of
          hygiene and exceptional washing results.
        </strong>
        <div className="how_tips_row">
          <h3>
            <img src={Ozone} className="tips_icon" />
            What is ozone technology?
          </h3>
          <div className="how_tips_row_text">
            <p>
              Ozone washing machine technology uses electricity and oxygen in a
              unique way to replace most of the chemicals you would find in the
              more standard, traditional laundry washing process.
            </p>
            <p>
              We can achieve high-quality washing results without using hot
              water or hazardous chemicals. There are so many benefits to using
              Ozone but one of the biggest is that it is more environmentally
              friendly.
            </p>
          </div>
        </div>
        <div className="how_tips_row">
          <h3>
            <img src={Eco} className="tips_icon" />
            Benefits to the planet &amp; your clothes
          </h3>
          <div className="how_tips_row_text">
            <p>
              Ozone wash <span>cleans and sanitises your laundry better</span>.
              Ozone is such a powerful oxidant that it kills microorganisms
              3,000 times faster than bleach.
            </p>
            <p>
              Ozone wash helps your laundry{" "}
              <span>smell better and feel softer</span>.
            </p>
            <p>
              Ozone wash is more <span>environmentally friendly</span>. Ozone
              laundry doesn’t require hot water, which means it consumes{" "}
              <span>less energy</span>. It also requires less chemicals to clean
              and sanitise effectively, which means{" "}
              <span>less rinsing water</span> is needed and fewer chemicals are
              discharged into the environment in wastewater.
            </p>
          </div>
        </div>
      </section>
      <section className="how__tutorial">
        <h2>Watch tutorial</h2>
        <video width="50%" controls>
            <source src={Tutorial} type="video/mp4" style={{aspectRatio: "16/9"}} />
        </video>
      </section>
      <Footer />
    </main>
  );
}
