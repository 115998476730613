import "./styles/navigation.scss";
import Logo from "./images/SpeedQueen_WorldNo1InSelfServiceLaundry_LicenseStore_Reverse_CMYK.webp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useEffect, useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Square from "./images/square_logo.webp";
import moment from "moment"

export default function Nav(props) {
  const [mobile, setMobile] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 690) {
      setMobile(true);
      // console.log(`mobile - ${window.innerWidth}`);
    } else {
      if (props.page != "home") {
        document.getElementById(props.page).style.color = "#e63b11";
      }
    }
  }, [window.innerWidth]);

  const handleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleChat = () => {
    var currentTime = moment();
    var morning = moment("08:30 am", "HH:mm a");
    var night = moment("05:00 pm", "HH:mm a");
    const phone = currentTime.isBetween(morning, night);
    if (phone) {
      document.location.href = "tel:01415303138";
    } else {
      window.open("https://wa.me/message/CJU6ZURKMONWL1", "_blank");
    }
  }

  return (
    <>
      {mobile ? (
        <nav>
          <img
            src={Logo}
            alt="Speed Queen Logo"
            onClick={() => navigate("/")}
          />
          <MenuOpenIcon className="menu_icon" onClick={() => handleMenu()} />
          {mobileMenu ? (
            <div className="mobile_menu">
              <CloseIcon
                className="mobile_menu_icon"
                onClick={() => handleMenu()}
              />
              <img src={Square} alt="Square logo" />
              <h4 id="how" onClick={() => navigate("/how-it-works")}>
                How it works
              </h4>
              <a href="/#prices">
                <h4 id="pricing">Pricing</h4>
              </a>
              <h4 id="locator" onClick={() => navigate("/locations")}>
                Locator
              </h4>
              <h4 id="app" onClick={() => navigate("/get-the-app")}>
                Get the APP
              </h4>
              <h4 id="faq" onClick={() => navigate("/faq")}>
                FAQ
              </h4>
              <h4 id="contact" onClick={() => navigate("/contact")}>
                Contact
              </h4>
            </div>
          ) : (
            <></>
          )}
        </nav>
      ) : (
        <nav>
          <img
            src={Logo}
            alt="Speed Queen Logo"
            width="30%"
            onClick={() => navigate("/")}
          />
          <div className="nav__menu">
            <h4 id="how" onClick={() => navigate("/how-it-works")}>
              How it works
            </h4>
            <a href="/#prices">
              <h4 id="pricing">Pricing</h4>
            </a>
            <h4 id="locator" onClick={() => navigate("/locations")}>
              Locator
            </h4>
            <h4 id="app" onClick={() => navigate("/get-the-app")}>
              Get the APP
            </h4>
            <h4 id="faq" onClick={() => navigate("/faq")}>
              FAQ
            </h4>
            <h4 id="contact" onClick={() => navigate("/contact")}>
              Contact
            </h4>
            <a onClick={handleChat}>
              <h4 className="nav_phone">
                <LocalPhoneIcon /> (+44) 0141 530 3138
              </h4>
            </a>
          </div>
        </nav>
      )}
    </>
  );
}
