import Footer from "./Footer";
import Nav from "./Nav";
import { useState } from "react";
import "./styles/home.scss";
import { IconButton } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import DescriptionIcon from "@mui/icons-material/Description";
import Photo from './images/pricing_bgd.webp'

export default function Investors() {
  const [investorsPass, setInvestorsPass] = useState(false);
  const pass = "InvestSpeedQueen";
  const [password, setPassword] = useState("");

  const handlePass = () => {
    if (password === pass) {
      setInvestorsPass(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handlePass();
    }
  };

  return (
    <main className="investors__page">
      <Nav page="home" />
      <section className="investors">
        {!investorsPass ? (
          <div className="investor_row">
            <h1>Please insert the password:</h1>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <IconButton onClick={handlePass}>
              <LoginIcon sx={{ color: "#e63b11" }} fontSize="large" />
            </IconButton>
          </div>
        ) : (
          <div className="investor_pack">
            <h1>See Investor pack</h1>
            <a
              className="download__btn"
              href="https://nitro-training.s3.eu-west-2.amazonaws.com/2023+Speed+Queen+Presentation+Updated.pdf"
              target="_blank"
              download={`Speed Queen Investor Pack`}
            >
              <DescriptionIcon className="btn_icon" />
              View
            </a>
            <img src={Photo} alt="speed queen image" />
          </div>
        )}
      </section>
      <Footer />
    </main>
  );
}
