import "../styles/faq.scss";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SqApp() {
  const [showId, setShowId] = useState();

  const handleShow = (id) => {
    if (showId === id){
        setShowId(0)
    }else{
        setShowId(id);
    }
  };

  return (
    <main className="services__tab">
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(1)}>
          <h3>What if the APP will not download?</h3>
          {showId === 1 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 1 ? (
          <div className="open__tab">
            <p>
              Ensure you are connected to WiFi or cellular data and have
              cellular service.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(2)}>
          <h3>What if I didn't receive the email verification?</h3>
          {showId === 2 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 2 ? (
          <div className="open__tab">
            <p>
              Check spam/junk folders and confirm your email address was input
              correctly.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(3)}>
          <h3>Why can't I login?</h3>
          {showId === 3 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 3 ? (
          <div className="open__tab">
            <p>
              Ensure you have verified your account with the verification code
              sent via email.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(4)}>
          <h3>I'm having trouble adding money to my wallet?</h3>
          {showId === 4 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 4 ? (
          <div className="open__tab">
            <p>Verify payment details are correct.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(5)}>
          <h3>Why can't I pay Speed Queen Rewards?</h3>
          {showId === 5 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 5 ? (
          <div className="open__tab">
            <p>
              Go to Settings and check balance. There must be enough Rewards
              credits to pay for the entire payment, a Rewards payment may not
              be combined with any other payment.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(6)}>
          <h3>Why can't I select my desired washer/dryer?</h3>
          {showId === 6 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 6 ? (
          <div className="open__tab">
            <p>Check the machine number and ensure it is not already in use.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(7)}>
          <h3>Why can't I select my desired washer/dryer?</h3>
          {showId === 7 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 7 ? (
          <div className="open__tab">
            <p>Check the machine number and ensure it is not already in use.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(8)} >
          <h3>Why can't I start my machine?</h3>
          {showId === 8 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 8 ? (
          <div className="open__tab">
            <p>
              Be sure Rewards or account balance has the required dollar amount
              shown. Reward credits must cover the full payment and cannot be
              split with another payment type.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(9)} >
          <h3>Why won't my machine start after selection?</h3>
          {showId === 9 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 9 ? (
          <div className="open__tab">
            <p>
              Ensure the correct machine has been selected and paid for in the
              app before pressing Start on the machine. Start must be pressed on
              the machine.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className="tab__title" onClick={() => handleShow(9)} >
          <h3>Why is my notification of cycle completion not sending?</h3>
          {showId === 9 ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </div>
        {showId === 9 ? (
          <div className="open__tab">
            <p>
              Be sure you have opted-in to receive notifications via text, email
              or push notifications and that the setting is turned on.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}
