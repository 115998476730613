import Footer from "./Footer";
import Nav from "./Nav";
import "./styles/form.scss";
import axios from "axios";
import { createRef, useState, useEffect } from "react";
import { Alert } from "@mui/material";

export default function Help() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [machine, setMachine] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [notes, setNotes] = useState("");
  //alerts
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  //captcha
  const [captchaSelc, setCaptchaSelc] = useState(false);

  const [selectedSite, setSelectedSite] = useState("Please Select A Site");
  const [paymentType, setPaymentType] = useState("The Speed Queen App");
  const [cardNo, setCardNo] = useState("");

  const captchaValue = () => {
    setCaptchaSelc(!captchaSelc);
  };

  const [sites, setSites] = useState([]);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen/")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submit = () => {
    document.getElementById("help_btn").style.display = "none";
    let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (email.match(regex)) {
      if (
        name === "" ||
        email === "" ||
        machine === "" ||
        date === "" ||
        time === "" ||
        selectedSite === "" ||
        selectedSite === "Please Select A Site"

        || (paymentType != "The Speed Queen App" && cardNo == "")
      ) {
        setMessage("All fields must be filled in");
        setSeverity("warning");
        setWarningAlert(true);
        setTimeout(() => {
          setWarningAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById(
            "help_btn"
          ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
        }, 3000);
      } else if (!captchaSelc) {
        setMessage("Please confirm you are not a robot");
        setSeverity("warning");
        setWarningAlert(true);
        setTimeout(() => {
          setWarningAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById(
            "help_btn"
          ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
        }, 3000);
      } else {
        //axios request to Nitrous BE
        let data = {
          name: name,
          email: email,
          machine: machine,
          date: date,
          time: time,
          message: notes,
          selectedSite: selectedSite,
          paymentType: paymentType,
          cardNo: paymentType != "The Speed Queen App" ? cardNo : ""
        };
        axios
          .post("https://nitrous.rt-itservices.co.uk/speedqueen/help", data)
          .then((res) => {
            setMessage("Form Sent");
            setSeverity("success");
            setWarningAlert(true);
            setTimeout(() => {
              setWarningAlert(false);
              setMessage("");
              setSeverity("");
              setName("");
              setEmail("");
              setMachine("");
              setDate("");
              setTime("");
              setNotes("");
              setCardNo("");
              setSelectedSite("Please Select A Site");
              setPaymentType("The Speed Queen App");
              document.getElementById(
                "help_btn"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
            }, 3500);
          })
          .catch((err) => {
            setMessage("Something went wrong, please try again");
            setSeverity("error");
            setWarningAlert(true);
            setTimeout(() => {
              setWarningAlert(false);
              setMessage("");
              setSeverity("");
              document.getElementById(
                "help_btn"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
            }, 3500);
          });
      }
    } else {
      setMessage("Email has the wrong format, please check and try again");
      setSeverity("warning");
      setWarningAlert(true);
      setTimeout(() => {
        setWarningAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById(
          "help_btn"
        ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
      }, 3000);
    }
  };
  return (
    <main className="help__page">
      <Nav page="contact" />
      <h2>Had an issue?</h2>
      <strong>
        We can help you on the phone Monday to Friday from 8.30am to 5.00pm, if
        you want to speak to an advisor please call us on{" "}
        <a href="tel:01415303138">0141 530 3138</a>.
        Alternatively you can also chat with us on Whatsapp clicking <a href="https://wa.me/message/CJU6ZURKMONWL1" target="_blank">here</a>
      </strong>
      <section className="help_form">
        <label for="name">Full Name:</label>
        <input
          type="text"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <label for="email">Email:</label>
        <input
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <label for="site">Site</label>
        <select className="site"
          onChange={(e) => {
            setSelectedSite(e.target.value)
          }}
          value={selectedSite}
        >
          <option>Please Select A Site</option>
          {sites.map(function (site, i) {
            return (
              <option>{site.name}</option>
            )
          })}
        </select>
        <label for="machine">Machine Number:</label>
        <input
          type="numer"
          pattern="[0-9]*"
          name="mahine"
          onChange={(e) => setMachine(e.target.value)}
          value={machine}
        />
        <label for="day">When did this error happen?</label>
        <input
          type="date"
          name="day"
          onChange={(e) => setDate(e.target.value)}
          value={date}
        />
        <label for="time">What time?</label>
        <input
          type="time"
          name="time"
          onChange={(e) => setTime(e.target.value)}
          value={time}
        />
        <label for="notes">Describe your issue:</label>
        <textarea
          name="notes"
          cols={40}
          rows={5}
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
        />

        {/* PAYMENT STUFF */}

        <label for="paymentType" >How did you pay?</label>
        <select
          onChange={(e) => setPaymentType(e.target.value)}
          value={paymentType}
        >
          <option>The Speed Queen App</option>
          <option>Physical Card</option>
          <option>Smart Device (Smart Watch, Mobile Phone)</option>
        </select>

        {paymentType != "The Speed Queen App" ? (
          <div className={"cardDetails"}>

            {paymentType == "Smart Device (Smart Watch, Mobile Phone)" ? (

              <strong>
                <p>This is different from your physical card number and can be found on your phones wallet app.
                  Further help with {" "}
                  <a href="https://www.samsung.com/us/support/answer/ANS00045169/" target="_blank">
                    Samsung
                  </a> or {" "}
                  <a href="https://support.apple.com/en-us/118544#:~:text=On%20your%20iPhone,app%20and%20tap%20Apple%20Card.&text=%2C%20then%20authenticate%20with%20Face%20ID,see%20your%20virtual%20card%20number" target="_blank">
                    Apple
                  </a> devices.
                </p>
              </strong>
            ) : null}

            <label for="machine">Last 4 Digits of your Card</label>
            <input
              type="numer"
              pattern="[0-9]"
              name="cardnumber"
              onChange={(e) => setCardNo(e.target.value)}
              maxLength={4}
              value={cardNo}
            />
          </div>
        ) :
          null
        }

        <div className="captcha">
          <input type="checkbox" onChange={captchaValue} value={captchaSelc}/>
          <p>I am not a robot</p>
        </div>
        <button id="help_btn" onClick={() => submit()}>
          Submit
        </button>
        {warningAlert ? (
          <Alert severity={severity} sx={{ marginTop: "2%" }}>
            {message}
          </Alert>
        ) : (
          <></>
        )}
      </section>
      <Footer />
    </main>
  );
}
